.box-animation-fadeout {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  background: #333;

  &__wrapper {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 200px;
      z-index: 100;
      top: 0px;
      left: 0px;
      opacity: 0;

      // When the checkbox is checked...
      &:checked {

        // Slide the first panel
        &~div.box-animation-fadeout__panel--cover {
          opacity: 0;
        }

      }
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    background: orange;
    color: #fff;
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    opacity: 1;
    transition: all 0.4s ease-out;
    z-index: 2;

    &--cover {
      justify-content: center;
    }
  }

  &__content {
    background: #333;
    color: #fff;
    position: absolute;
    text-align: center;
    z-index: 1;
    width: 100%;
    max-width: 200px;
    max-height: 200px;

    img {
      padding-top: 65px;
    }

  }
}