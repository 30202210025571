.box-animation-starship {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  background: #333;

  &__wrapper {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 200px;
      z-index: 100;
      bottom: 0px;
      left: 0px;
      opacity: 0;

      // When the checkbox is checked...
      &:not(:checked) {

        // Slide the first panel
        &~div.box-animation-starship__panel--left {
          transform: translateX(0);
        }

        // Slide the second panel
        &~div.box-animation-starship__panel--right {
          transform: translateX(0);
        }

      }

    }

  }

  &__panel {
    display: flex;
    align-items: center;
    background: orange;
    color: #fff;
    float: left;
    position: relative;
    width: 50%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    transition: all 1s ease-out;
    z-index: 2;

    &--left {
      justify-content: flex-end;
      transform: translateX(-100%);
    }

    &--right {
      justify-content: flex-start;
      transform: translateX(100%);
    }

  }

  &__content {
    background: #333;
    color: #fff;
    width: 100%;
    max-width: 200px;
    position: absolute;
    text-align: center;
    z-index: 1;

    img {
      padding-top: 65px;
    }
  }
}