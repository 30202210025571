.box-animation-window {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  background: #333;

  &__wrapper {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 200px;
      z-index: 100;
      top: 0px;
      left: 0px;
      opacity: 0;

      // When the checkbox is not checked...
      &:not(:checked) {

        // Slide the first panel
        &~div.box-animation-window__panel--cover {
          transform: translateX(0);
        }
      }
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    background: orange;
    color: #fff;
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1400px;
    transform-style: preserve-3d;
    transform-origin: 0;
    transform-origin: left;
    -webkit-transform-style: preserve-3d;
    backface-visibility: inherit;
    /*Speed of the Door animation*/
    transition: all 0.2s ease-out;
    z-index: 2;

    &--cover {
      justify-content: center;
      transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
    }
  }

  &__content {
    background: #333;
    color: #fff;
    position: absolute;
    text-align: center;
    z-index: 1;
    width: 100%;
    max-width: 200px;
    max-height: 200px;

    img {
      padding-top: 65px;
    }
  }
}