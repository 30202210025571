.box-animation-singledoor {
  margin: 0 auto;
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  background: #333;

  &__wrapper {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 200px;

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 200px;
      z-index: 100;
      bottom: 0px;
      left: 0px;
      opacity: 0;

      // When the checkbox is not checked...
      &:not(:checked) {

        // Slide the first panel
        &~div.box-animation-singledoor__panel--left {
          transform: translateX(0);
        }

        // Slide the first panel
        &~div.box-animation-singledoor__panel--right {
          transform: translateX(0);
        }

        // Slide the first panel
        &~div.box-animation-singledoor__panel--top {
          transform: translateY(0);
        }

        // Slide the first panel
        &~div.box-animation-singledoor__panel--bottom {
          transform: translateY(0);
        }

      }
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    background: orange;
    color: #fff;
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    transition: all 1s ease-out;
    z-index: 2;

    &--left {
      justify-content: center;
      transform: translateX(-100%);
    }

    &--right {
      justify-content: center;
      transform: translateX(100%);
    }

    &--top {
      justify-content: center;
      transform: translateY(-100%);
    }

    &--bottom {
      justify-content: center;
      transform: translateY(100%);
    }
  }

  &__content {
    background: #333;
    color: #fff;
    position: absolute;
    text-align: center;
    z-index: 1;
    width: 100%;
    max-width: 200px;
    max-height: 200px;

    img {
      padding-top: 65px;
    }
  }
}