.ant-modal-close{
  width: 22px;
  height: 22px;
  margin: 5px 5px 0 0;
}
.ant-modal-close-x {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.45) solid 1px;
  border-radius: 50%;
  width: 22px !important;
  height: 22px !important;
}