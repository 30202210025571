.float-left {
  float: left;
}
.float-right {
  float: right;
}
.react-form-builder .react-form-builder-preview {
  width: 80%;
}
.react-form-builder .react-form-builder-toolbar {
  width: 20%;
  margin-top: 0px;
}
.btn.btn-info.selected {
  background-color: #42d0ed;
  color: #f7f7f8;
}
.btn.btn-publish {
  background-color: #f0a540;
  border-color: #f0a540;
  color: #f7f7f8;
}
