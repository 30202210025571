@media (min-width: 992px) {
    .navbar-form {
        margin-top: 21px;
        margin-bottom: 21px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .navbar-nav>li>.dropdown-menu,
    .dropdown .dropdown-menu {
        @include transform-scale(0);
        @include transition($slow-transition-time, $transition-bezier);
    }

    .navbar-nav>li.open>.dropdown-menu,
    .dropdown.open .dropdown-menu {
        @include transform-scale(1);
        @include transform-origin($dropdown-coordinates);

    }

    .navbar-nav>li>.dropdown-menu:before {
        border-bottom: 11px solid rgba(0, 0, 0, 0.2);
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 12px;
        top: -11px;
    }

    .navbar-nav>li>.dropdown-menu:after {
        border-bottom: 11px solid #FFFFFF;
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 12px;
        top: -10px;
    }

    .navbar-nav.navbar-right>li>.dropdown-menu:before {
        left: auto;
        right: 12px;
    }

    .navbar-nav.navbar-right>li>.dropdown-menu:after {
        left: auto;
        right: 12px;
    }

    .footer:not(.footer-big) {
        nav>ul {
            li:first-child {
                margin-left: 0;
            }
        }
    }

    body>.navbar-collapse.collapse {
        display: none !important;
    }

    .card {
        form {
            [class*="col-"] {
                padding: 6px;
            }

            [class*="col-"]:first-child {
                padding-left: 15px;
            }

            [class*="col-"]:last-child {
                padding-right: 15px;
            }
        }
    }
}

/*          Changes for small display      */

@media (max-width: 991px) {
    .main-panel {
        width: 100%;

        >.content {
    
            .setting-submit{
                top: 60px;
    
                .btn-fill,.btn-info{ 
                    top: 65px; 
                }
            }
        }
    }

    .navbar-transparent {
        padding-top: 15px;
        background-color: rgba(0, 0, 0, 0.45);
    }

    body {
        position: relative;
    }

    .main-panel {
        @include transform-translate-x(0px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        left: 0;
    }

    .navbar .container {
        left: 0;
        width: 100%;
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        position: relative;
    }

    .navbar .navbar-collapse.collapse,
    .navbar .navbar-collapse.collapse.in,
    .navbar .navbar-collapse.collapsing {
        display: none !important;
    }

    .navbar-nav>li {
        float: none;
        position: relative;
        display: block;
    }

    .sidebar {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        width: 260px;
        right: 0;
        left: auto;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;
        padding: 0;

        @include transform-translate-x(260px);
        @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

        >ul {
            position: relative;
            z-index: 4;
            overflow-y: scroll;
            height: calc(100vh - 61px);
            width: 100%;
        }

        &::before {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: #282828;
            display: block;
            content: "";
            z-index: 1;
        }

        .sidebar-wrapper {
            .dropdown-menu {

                &>li:first-child>a,
                &>li:last-child>a {
                    border-radius: 4px;
                }

                &>li>a:hover,
                &>li>a:focus {

                    color: white;
                }
            }

            &>.nav>div {
                .nav {
                    margin-top: 10px;
                    float: none !important;

                    &>li>a {
                        margin: 0;
                        line-height: 30px;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin: 5px 15px;
                    }
                }

                .notification {
                    float: left;
                    line-height: 30px;
                    margin-right: 8px;
                    font-weight: 600;
                }
            }
        }

        .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }

    .nav-open {
        .sidebar {
            @include transform-translate-x(0px);
        }

        .navbar .container {
            left: -260px;
        }

        .main-panel {
            left: 0;
            @include transform-translate-x(-260px);
        }
    }

    .navbar-toggle .icon-bar {
        display: block;
        position: relative;
        background: #fff;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        margin: 0 auto;
    }

    .navbar-header .navbar-toggle {
        margin: 10px 15px 10px 0;
        width: 40px;
        height: 40px;
    }

    .bar1,
    .bar2,
    .bar3 {
        outline: 1px solid transparent;
    }

    .bar1 {
        top: 0px;
        @include bar-animation($topbar-back);
    }

    .bar2 {
        opacity: 1;
    }

    .bar3 {
        bottom: 0px;
        @include bar-animation($bottombar-back);
    }

    .toggled .bar1 {
        top: 6px;
        @include bar-animation($topbar-x);
    }

    .toggled .bar2 {
        opacity: 0;
    }

    .toggled .bar3 {
        bottom: 6px;
        @include bar-animation($bottombar-x);
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .dropdown-menu .divider {
        background-color: rgba(229, 229, 229, 0.15);
    }

    .navbar-nav {
        margin: 1px 0;

        .open .dropdown-menu>li {
            &>a {
                padding: 15px 15px 5px 50px;
            }

            &:first-child>a {
                padding: 5px 15px 5px 50px;
            }

            &:last-child>a {
                padding: 15px 15px 25px 50px;
            }
        }
    }

    [class*="navbar-"] .navbar-nav {

        &>li>a,
        >li>a:hover,
        >li>a:focus,
        .active>a,
        .active>a:hover,
        .active>a:focus,
        .open .dropdown-menu>li>a,
        .open .dropdown-menu>li>a:hover,
        .open .dropdown-menu>li>a:focus,
        .navbar-nav .open .dropdown-menu>li>a:active {
            color: white;
        }

        &>li>a,
        >li>a:hover,
        >li>a:focus,
        .open .dropdown-menu>li>a,
        .open .dropdown-menu>li>a:hover,
        .open .dropdown-menu>li>a:focus {
            opacity: .7;
            background: transparent;
        }

        &.navbar-nav .open .dropdown-menu>li>a:active {
            opacity: 1;
        }

        & .dropdown>a {
            &:hover .caret {
                border-bottom-color: #777;
                border-top-color: #777;
            }

            &:active .caret {
                border-bottom-color: white;
                border-top-color: white;
            }
        }

    }

    .dropdown-menu {
        display: none;
    }

    .navbar-fixed-top {
        -webkit-backface-visibility: hidden;
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 250px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
    }

    .social-line .btn {
        margin: $margin-bottom;
    }

    .subscribe-line .form-control {
        margin: $margin-bottom;
    }

    .social-line.pull-right {
        float: none;
    }

    .footer nav.pull-left {
        float: none !important;
    }

    .footer:not(.footer-big) nav>ul li {
        float: none;
    }

    .social-area.pull-right {
        float: none !important;
    }

    .form-control+.form-control-feedback {
        margin-top: -8px;
    }

    .navbar-toggle:hover,
    .navbar-toggle:focus {
        background-color: transparent !important;
    }

    .btn.dropdown-toggle {
        margin-bottom: 0;
    }

    .media-post .author {
        width: 20%;
        float: none !important;
        display: block;
        margin: 0 auto 10px;
    }

    .media-post .media-body {
        width: 100%;
    }

    .navbar-collapse.collapse {
        height: 100% !important;
    }

    .navbar-collapse.collapse.in {
        display: block;
    }

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .navbar-collapse {
        .nav p {
            font-size: $font-size-base;
            margin: 0;
        }

        [class^="pe-7s-"] {
            float: left;
            font-size: 20px;
            margin-right: 10px;
        }
    }
}

//overwrite table responsive for 768px screens

@media (min-width: 992px) {
    .table-full-width {
        margin-left: -15px;
        margin-right: -15px;
    }

    .table-responsive {
        overflow: visible;
    }

}

@media (max-width: 991px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

}